import {computed, inject, isRef, nextTick} from "vue"
import {useStore} from "vuex"
import {useI18n} from "vue-i18n"
import GlobalService from "@/services/global_service"
import {useRouter} from "vue-router"

export function useAppUtils () {

  const store = useStore()
  const router = useRouter()
  const { t } = useI18n()
  const emitter: any = inject('emitter')

  const showMessage = async (text, params = {}) => {
    await store.dispatch('addNotification', Object.assign(params, {text}))
  }

  const showError = async (text, params = {}) => {
    console.log('showError', Object.assign(params, {text, type: 'error',}))
    await store.dispatch('addNotification', Object.assign(params, {text, type: 'error',}))
  }

  const showWarning = async (text, params = {}) => {
    await store.dispatch('addNotification', Object.assign(params, {text, type: 'warning',}))
  }

  const showGeneralError = async () => {
    await showError(t('An error ocurred, please try again'), {id: 'showGeneralError'})
  }

  const showFeatureNotImplemented = async () => {
    await showError(t('This feature is not implemented yet'), {id: 'featureNotImplemented'})
  }

  const hideNotification = async (id) => {
    await store.dispatch('removeNotification', id)
  }

  const isSuccessServerResponse = response => response && !response.isError

  const showResponseSuccessMessage = async (response, params = {}) => {
    if (isSuccessServerResponse(response) && response.message && store) {
      await showMessage(response.message, params)
      if (Array.isArray(response?.xdata?.messages)) {
        const extraMessage = response.xdata.messages.join('<br>')
        await nextTick()
        Object.assign(params, {
          icon: {name: "automator-arrow", width: "60", height: "60"}
        })
        await showMessage(extraMessage, params)
      }
    }
  }

  const _tempElement = document.createElement('div')
  const _tempElementForEscapeAttr = document.createElement('p')
  const _getTempElement = () => _tempElement
  const _getTempElementForEscapeAttr = () => _tempElementForEscapeAttr

  const vEscape = (unescapedHtmlString: string) => {
    _getTempElement().innerText = unescapedHtmlString
    return _getTempElement().innerHTML
  }

  const vUnescape = (escapedHtmlString: string) => {
    _getTempElement().innerHTML = escapedHtmlString
    return _getTempElement().innerText
  }

  const vEscapeAttr = (unescapedHtmlString: string) => {
    const elem = _getTempElementForEscapeAttr()
    elem.setAttribute('data-custom-attribute', unescapedHtmlString)
    const elemHtml = elem.outerHTML
    // Find out whether the browser used single or double quotes before encodedText
    const quote = elemHtml[elemHtml.search(/['"]/)]
    // Split up the generated HTML using the quote character; take item 1
    const result = elemHtml.split(new RegExp(quote))[1]
    return result
  }

  const vConfirm = async (text) => {
    return await store.dispatch('addConfirm', {text})
  }

  const getCurrentScreen = () => {
    return store.getters.currentScreen
  }

  const isChangedScreen = () => {
    const screen = getCurrentScreen()
    return screen ? store.getters[screen + '/isChanged'] : false
  }

  const isMayContinue = async (params = {forceConfirm: false}) => {
    let result = true
    if (params.forceConfirm || isChangedScreen()) {
      result = await vConfirm(t('Continue without Saving the updates?'))
      if (result) {
        emitter.emit('ContinueWithoutSavingConfirmed')
      }
    }
    return result
  }

  const navigateWithConfirm = async (event, to) => {
    console.log('navigateWithConfirm', to)
    event.preventDefault()
    if (await isMayContinue()) {
      await router.push(to)
    }
  }

  const getAuthUser = () => store.getters['auth/authUser']

  const getCurrentProjectId = () => store.getters.project?.id

  const hideStartLoader = () => {
    document.getElementById("sq_loader")!.style.display = 'none'
  }

  const showElementLoader = (selector, options:{classes?: string, scale?: number} = {classes: '', scale: 0.5}) => {
    store.dispatch('addLoader', {type: 'app-loader', to: selector, ... options})
  }

  const hideElementLoader = async (selector) => {
    await nextTick()
    store.commit('removeLoader', {type: 'app-loader', to: selector})
  }

  const getPanelsDivider = () => document.querySelector('.screen-panel-divider')

  const disablePanelsResizing = () => {
    const panelsDivider = getPanelsDivider()
    if (panelsDivider) {
      panelsDivider.classList.add('pointer-events-none')
    }
  }

  const enablePanelsResizing = () => {
    const panelsDivider = getPanelsDivider()
    if (panelsDivider) {
      panelsDivider.classList.remove('pointer-events-none')
    }
  }
  //const mainBgColor = getComputedStyle(document.documentElement).getPropertyValue('--main-bg-color').trim();
  const showFullPanelsLoader = (classes = '') => {
    store.dispatch('addLoader', {to: '.main-container', type: 'app-loader', classes})
  }

  const hideFullPanelsLoader = async () => {
    await nextTick()
    store.commit('removeLoader', {to: '.main-container', type: 'app-loader'})
  }

  const showLeftPanelLoader = (classes = '') => {
    disablePanelsResizing()
    store.dispatch('addLoader', {to: '.screen-panel-left', type: 'app-loader', classes})
  }

  const hideLeftPanelLoader = async () => {
    await nextTick()
    store.commit('removeLoader', {to: '.screen-panel-left', type: 'app-loader'})
    enablePanelsResizing()
  }

  const showRightPanelLoader = (classes = '') => {
    disablePanelsResizing()
    store.dispatch('addLoader', {to: '.screen-panel-right', type: 'app-loader', classes})
  }

  const hideRightPanelLoader = async () => {
    await nextTick()
    store.commit('removeLoader', {to: '.screen-panel-right', type: 'app-loader'})
    enablePanelsResizing()
  }

  const isTwoPanelsLayout = () => !!(document.querySelector('.screen-panel-left') && document.querySelector('.screen-panel-right'))

  const showPanelsLoaders = (panelLoader: 'left' | 'right' | 'both' = 'both') => {
    if (isTwoPanelsLayout()) {
      if (panelLoader === 'left' || panelLoader === 'both') {
        showLeftPanelLoader()
      }
      if (panelLoader === 'right' || panelLoader === 'both') {
        showRightPanelLoader()
      }
    } else {
      showFullPanelsLoader()
    }
  }

  const hidePanelsLoaders = async () => {
    await nextTick()
    hideLeftPanelLoader()
    hideRightPanelLoader()
    hideFullPanelsLoader()
  }

  let __scrollbarWidth: number | null = null

  const getScrollbarWidth = () => {
    if (__scrollbarWidth === null) {
      const divElement = document.createElement('div')
      divElement.style.cssText = 'overflow: scroll; position: absolute; top: -1000px;'
      document.body.appendChild(divElement)
      __scrollbarWidth = (divElement.offsetWidth - divElement.scrollWidth) || 6
      document.body.removeChild(divElement)
    }
    return __scrollbarWidth
  }

  return {

    getUrlRoot: () => process.env.NODE_ENV === 'production' || process.env.VUE_APP_CONTAINER === 'docker' ? process.env.VUE_APP_PUBLIC_PATH : '/',

    // el - DOM element | Element ref | Component ref
    getDomElement(el) {
      let element = el

      if (isRef(el)) {
        element = el.value
      }

      if (!element) {
        return
      }

      if (element['__isVue']) {
        element = element['$el']
      }
      return element
    },

    loadScript: (fileURL, domElementAttributes = {}) => {
      return new Promise((resolve, reject) => {
        try {
          const domElement = document.createElement("script")
          domElement.type = "text/javascript"
          domElement.async = true
          domElement.src =fileURL

          for (const key in domElementAttributes) {
            domElement[key] = domElementAttributes[key]
          }

          domElement.addEventListener("load", () => {
            resolve({ status: true })
          })

          domElement.addEventListener("error", () => {
            reject({
              status: false,
              message: `Failed to load the script ${fileURL}`
            })
          })

          document.head.appendChild(domElement)
        } catch (error) {
          reject(error)
        }
      })
    },

    loadStyle: (fileURL, domElementAttributes = {}) => {
      return new Promise((resolve, reject) => {
        try {
          const domElement = document.createElement("link")
          domElement.type = "text/css"
          domElement.rel = 'stylesheet'
          domElement.media = 'all'
          domElement.href = fileURL

          for (const key in domElementAttributes) {
            domElement[key] = domElementAttributes[key]
          }

          domElement.addEventListener("load", () => {
            resolve({ status: true })
          })

          domElement.addEventListener("error", () => {
            reject({
              status: false,
              message: `Failed to load the style ${fileURL}`
            })
          })

          document.head.appendChild(domElement)
        } catch (error) {
          reject(error)
        }
      })
    },

    copyToClipboard: function (text, success_msg, fail_msg = t('Copy to clipboard failed')) {
      if (text && text.trim()) {
        if (window['clipboardData']?.setData) {
          // IE specific code path to prevent textarea being shown while dialog is visible.
          return window['clipboardData'].setData("Text", text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          const textarea = document.createElement("textarea");
          textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
          textarea.style.left = '-10000px';
          textarea.style.top = '10000px';
          textarea.style.opacity = '0';
          textarea.textContent = text;
          document.body.appendChild(textarea);
          textarea.select();
          try {
            const success = document.execCommand("copy");  // Security exception may be thrown by some browsers.
            success_msg && showMessage(success_msg, {id: 'copyToClipboard'})
            return success;
          } catch (ex) {
            showError(fail_msg)
            return false;
          } finally {
            document.body.removeChild(textarea);
          }
        }
      } else {
        showWarning(fail_msg)
      }
    },

    restoreEvent: async (id: string) => {
      try {
        const data = await GlobalService.restoreEvent(id)
        if (store && !data.isError && data.message) {
          await store.dispatch('addNotification', {
            text: data.message,
          })
        }
        console.log('useAppUtils restoreEvent success', data)
        return data
      } catch (e) {
        console.log('useAppUtils restoreEvent error', e)
        return null
      }
    },

    getMinDarkColor: () => {
      let minDarkColor = parseInt(store.state?.config?.sticker_dark_color, 10)
      if (isNaN(minDarkColor)) {
        minDarkColor = 10
      }
      return minDarkColor
    },

    getDragEventPageX: event => {
      let pageX: number | null = null
      //console.log('getPageX', event.targetTouches, event.targetTouches && event.targetTouches[0], event.targetTouches && event.targetTouches[0] && event.targetTouches[0].pageX)
      if (event.targetTouches && event.targetTouches[0] && event.targetTouches[0].pageX) {
        pageX = event.targetTouches[0].pageX
      } else if (event.pageX) {
        pageX = event.pageX
      }
      return pageX
    },

    getScrollParent(element, includeHidden = false) {
      let style = getComputedStyle(element)
      const excludeStaticParent = style.position === "absolute"
      const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/
      const scrollingElement = document.scrollingElement || document.body
      let parent = element

      if (style.position === "fixed") {
        return scrollingElement
      }

      while(parent) {
        parent = parent.parentElement
        style = getComputedStyle(parent)
        if (excludeStaticParent && style.position === "static") {
          continue
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
          return parent
        }
      }

      return scrollingElement
    },

    get2SymbolsLanguage() {
      return computed(() => {
        const siteLang = store.getters['settings/getAll']['site_lang']
        let lang = (siteLang && siteLang.split('_')[0]) || 'en'
        if (lang === 'jp') {
          lang = 'ja'
        }
        return lang
      })
    },

    getTouchDragDelay: () => 200,
    getExpanderColumnWidth: () => 29,

    nl2br(str, replaceMode=false, isXhtml=false) {
      const breakTag = (isXhtml) ? '<br />' : '<br>'
      const replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
    },

    showMessage,
    showError,
    showWarning,
    isSuccessServerResponse,
    showResponseSuccessMessage,
    showFeatureNotImplemented,
    showGeneralError,
    hideNotification,
    getCurrentScreen,
    getCurrentProjectId,
    isChangedScreen,
    isMayContinue,
    vEscape,
    vUnescape,
    vEscapeAttr,
    vConfirm,
    navigateWithConfirm,
    getAuthUser,
    hideStartLoader,
    showElementLoader,
    hideElementLoader,
    showFullPanelsLoader,
    hideFullPanelsLoader,
    showLeftPanelLoader,
    hideLeftPanelLoader,
    showRightPanelLoader,
    hideRightPanelLoader,
    showPanelsLoaders,
    hidePanelsLoaders,
    getScrollbarWidth,
  }
}
