import _uniqueId from "lodash/uniqueId"
import _debounce from 'lodash/debounce'

let resizeObserver: any = null
const resizeObserverItems = new Map()

const resizeHandler = (entries) => {
  for (const entry of entries) {
    resizeObserverItems.forEach(item => {
      if (item.target === entry.target) {
          item.callback(entry)
      }
    })
  }

}

if ('ResizeObserver' in self) {
  resizeObserver = new window['ResizeObserver'](resizeHandler);
}

const addResizeObserverItem = (target, callback) => {
  const uid = _uniqueId('ResizeObserverItem')
  if (resizeObserver && target && typeof callback === 'function') {
    resizeObserverItems.set(uid, {
      uid,
      target,
      callback: _debounce(callback, 45),
    })
    resizeObserver.unobserve(target)
    resizeObserver.observe(target)
  }
  return uid
}

const removeResizeObserverItem = (uid) => {
  if (uid) {
    resizeObserverItems.delete(uid)
    //resizeObserver.unobserve(target)
  }
}

export function useResizeObserver() {

  return {
    resizeObserver,
    addResizeObserverItem,
    removeResizeObserverItem,
  }

}
