import {createApp, isRef} from "vue"
import {IData} from "@/types"
import {useLocale} from "@/composables/useLocale"
import ApiService from "@/services/api_service"
import mitt from "mitt"
import axios from "axios"
import CircleTabulation from "@/directives/CircleTabulation"
import TabOnEnter from "@/directives/TabOnEnter"
import TabOnArrows from "@/directives/TabOnArrows"
import DropdownKeysNavigation from "@/directives/DropdownKeysNavigation"
import {TokenService} from "@/services/storage_service"
import store from "@/store"
import AuthService from "@/services/auth_service"
import GlobalService from "@/services/global_service"
import VueTippy from "vue-tippy"
import vueTippyDefaultOptions from "@/data/vue-tippy-defaults"
import FloatingVue from "floating-vue"

// el - DOM element | Element ref | Component ref
export function getDomElement(el) {
  let element = el

  if (isRef(el)) {
    element = el.value
  }

  if (!element) {
    return
  }

  if (element['__isVue']) {
    element = element['$el']
  }
  return element
}

export function getKeyboardFocusableElements (
  element: HTMLElement | HTMLDocument | null | undefined = document,
  options: {omitServiceElements: true | false} = {omitServiceElements: false}) {
  // https://github.com/makeup-jquery/jquery-focusable/blob/master/jquery.focusable.js
  // https://zellwk.com/blog/keyboard-focusable-elements/

  const focusableElementsList = [
    'a[href]',
    'button:not([disabled])',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'iframe',
    'object',
    'embed',
    '*[tabindex]:not([tabindex="-1"]',
    '*[contenteditable]'
  ]

  const focusableElementsSelector = focusableElementsList.join()

  let result: any[] = []
  if (element) {
    result = [...element.querySelectorAll(focusableElementsSelector)]
      .filter(el => el.getAttribute('tabindex') !== '-1')

    if (options.omitServiceElements) {
      result = result.filter(el => !el.hasAttribute('data-circle-tabulation')) // elements from v-circle-tabulation directive
    }
  }

  return result
}

const logger = window['disarea'] && window['disarea']['logger']
const errorLogger = window['disarea'] && window['disarea']['errorLogger']

export function initApp(appType, App, router) {

  if (typeof logger?.setLoggerBaseData === 'function') {
    logger.setLoggerBaseData({app: appType})
  }

  const app = createApp(App)

  if (!window['disarea']) {
    window['disarea'] = {}
  }
  axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"
  app.config.globalProperties.$http = axios;

  if (TokenService.getToken()) {
    ApiService.setHeader()
  }
  ApiService.mount401Interceptor()

  const emitter = mitt()
  app.provide('emitter', emitter)

  if (appType === 'index') {
    ApiService._axios.interceptors.request.use((config)=>{
      emitter.emit('start_loading');
      return config;
    })
    ApiService._axios.interceptors.response.use((config)=>{
      emitter.emit('end_loading');
      return config;
    })

    app
      .directive('circle-tabulation', CircleTabulation)
      .directive('tab-on-enter', TabOnEnter)
      .directive('tab-on-arrows', TabOnArrows)
      .directive('dropdown-keys-navigation', DropdownKeysNavigation)


    window['event_restore'] = function (id) {
      emitter.emit('event_restore', id)
    }

    window['tabs_man'] = {
      people: {
        confirmAndConvertCustomer(email, id) {
          emitter.emit('confirmAndConvertCustomer', {email, id})
        }
      }
    }

    window['disarea'].openMyAccount = () => {
      emitter.emit('openMyAccount')
    }
  }

  if (appType === 'login') {
    runLoginApp(app, router)
  } else {
    initTabUid()
    runApp(appType, app, router)
  }
}

const initErrorLogger = (appType, app, serverData) => {

  if (logger && typeof logger.setLoggerBaseData === 'function') {
/*
    const requestParams = {}
    if (serverData.c_srf) {
      Object.assign(requestParams, {c_srf: serverData.c_srf})
    }
    if (tabUid) {
      Object.assign(requestParams, {tabUid})
    }
*/

    const requestData = {
      app: appType,
      site_lang: serverData?.model?.settings?.site_lang || serverData?.localeParams?.locale,
      authUser: {
        id: serverData?.model?.authUser?.id,
      }
    }

    if (typeof logger.encodeLoggerValue === 'function') {
      if (serverData?.model?.authUser?.name) {
        Object.assign(requestData.authUser, {name: logger.encodeLoggerValue(serverData.model.authUser.name)})
      }
      if (serverData?.model?.authUser?.email) {
        Object.assign(requestData.authUser, {email: logger.encodeLoggerValue(serverData.model.authUser.email)})
      }
    }
    //logger.setLoggerBaseData(requestParams, requestData)
    logger.setLoggerBaseData(requestData)
  }

  if (errorLogger) {
    app.config.errorHandler = (err, vm, info) => {
      const errObj = err as IData

      if(typeof errorLogger.logData === 'function') {
        try {
          const isChunkLoadError = window['disarea'].isChunkLoadError(errObj)
          if (isChunkLoadError) {
            const dataForLog = {
              source: `app.config.errorHandler`,
              error: {
                name: errObj.name,
                message: errObj.message
              }
            }
            window['disarea'].reloadOnError('', dataForLog)
          } else {
            errorLogger.logData({
              source: `app.config.errorHandler`,
              message: errObj?.message,
              info,
              stack: errObj?.stack,
            })
          }
        } catch (e) {
          console.log(`app.config.errorHandler exception`, e)
          errorLogger.logData({[`app.config.errorHandler exception`]: e.message})
        }
      }
    }

    app.config.warnHandler = function(message, vm, trace) {
      errorLogger.logData({
        source: `app.config.warnHandler`,
        message,
        trace,
      })
    }

  }

}

const logStartError = (appType, e) => {
  if(typeof errorLogger.logData === 'function' && e?.response?.status != 401) {
    errorLogger.logData({'app start error': e.message || e})
  }
}

const initTranslation = (response) => {
  const { createLocale } = useLocale()
  const i18n = createLocale(response.localeParams.locale, response.localeParams.messages)

  if (window['disarea']['isDebugMode'] && response.localeParams.locale === 'ru_RU') {
    const messagesCopy = {...response.localeParams.messages[response.localeParams.locale]}
    const initial_i18n_t = i18n.global.t
    i18n.global.t = (stringKey) => {
      const valueInTranslationFile = messagesCopy[stringKey]
      let translateResult = initial_i18n_t(stringKey)
      if (!valueInTranslationFile) {
        translateResult = '%' + translateResult + '%'
      }
      return translateResult
    }
  }

  return i18n
}

const initDebugMode = (app, isDebugConfig) => {
  window['disarea']['isDebugMode'] = isDebugConfig && process.env.VUE_APP_DOMAIN_TYPE !== 'release'
  app.provide('isDebugMode', window['disarea']['isDebugMode'])
}

const initTabUid = () => {
  let tabUid = window.sessionStorage.getItem("disarea.tabUid")
  if (!tabUid) {
    tabUid = Date.now() + '_' + Math.random()
    window.sessionStorage.setItem("disarea.tabUid", tabUid)
  }
  ApiService.setTabUid(tabUid)
}

const storeMainResponse = (appType, response) => {

  if (response.Authorization) {
    store.commit('auth/loginSuccess', response.Authorization);
  }

  ApiService.setCSRF(response.c_srf)
  store.commit('auth/changeAuthUser', {authUser: response?.model?.authUser || {}});
  store.commit('settings/setSettings', response?.model?.settings || {});
  store.commit('setConfig', response?.model?.config || {})

  if (appType === 'index') {
    store.commit('updateProject', {project:response.model.defProject});
    store.commit('board/set_projectId', response.model.defProject.id);
    store.commit('updateProjects', [...response.projects]);
    store.commit('setLastUpdated', response.lastUpdated || '')
    store.commit('setHighlightLastUpdated', !!response.highlightLastUpdated)
    store.commit('setDemoDomain', !!response.demoDomain)
    const isTrialDomain = !!response.trialDomain
    if (isTrialDomain) {
      store.commit('setTrialDomain', true)
      store.commit('setTrialLeftTime', response.trialLeftTime)
      store.commit('setTrialTimeProgress', response.trialTimeProgress)
    }

    store.commit('ticketsfilters/setSearchInDefault', response.model.config.searchFields)

    if (response.topMessages) {
      store.commit('setTopMessages', response.topMessages || [])
    }

    if (response.showFeedbackPopup) {
      store.commit('setNeedShowSmileFeedback', true)
    }

    const panelsConfigs = response.model?.config?.panels
    if (panelsConfigs) {
      const screensWithPanels = Object.keys(panelsConfigs)
      screensWithPanels.forEach(screenName => {
        const screenPanelConfig = panelsConfigs[screenName]
        try {
          store.commit(screenName + '/setResizablePanel', screenPanelConfig['resizable_panel'])
          store.commit(screenName + '/setResizablePanelWidth', screenPanelConfig['width'])
          store.commit(screenName + '/setLeftPanelMinWidth', screenPanelConfig['min_width'])
          store.commit(screenName + '/setRightPanelMinWidth', screenPanelConfig['min_right_width'])
        } catch (e) {
          console.log('main panelsConfig error', screenName, e)
        }
      })
    }
  }

}

const getUrlParams = () => ({
  location_pathname: window.location.pathname,
  location_search: window.location.search,
  location_hash: window.location.hash
})

const runApp = (appType, app, router) => {
  GlobalService.get_main(getUrlParams()).then(async (response)=>{

    if (!response) {
      logStartError(appType, 'No response data')
      return
    }

    initDebugMode(app, !!response.model?.config?.debug)
    initErrorLogger(appType, app, response)
    const i18n = initTranslation(response)

    storeMainResponse(appType, response)

    app
      .use(store)
      .use(router)
      .use(i18n)
      .use(VueTippy, vueTippyDefaultOptions)
      .use(FloatingVue)
      .mount('#app')

    if (response.redirect) {
      await router.push(response.redirect)
    }

    if (response.errorMessage) {
      await store.dispatch('addNotification', {text: response.errorMessage, type: 'error'})
    }

  }).catch((e) => {
    logStartError(appType, e)
  })
}

const runLoginApp = (app, router) => {
  const appType = 'login'
  AuthService.preload_login(getUrlParams()).then(response => {
    const xdata = response.xdata

    if (!xdata) {
      logStartError(appType, 'No response data')
      return
    }

    initDebugMode(app, !!xdata?.config?.debug)
    initErrorLogger(appType, app, xdata)
    const i18n = initTranslation(xdata)

    app.provide('loginPreloadData', response)

    app.use(store).use(router).use(i18n).mount('#app')

  }).catch((e) => {
    logStartError(appType, e)
  })
}