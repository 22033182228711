import {ActionTree, GetterTree, MutationTree} from "vuex"
import {IData, ITicketTag, IUser, IReportPeriod, IReportHeaderStatistics, ITicket} from "@/types"
import {RootState} from "@/store"
import datatable from "@/store/modules/datatable"
import panels from "@/store/modules/panels"

export const filtersDefaultValues = {
  isCreated: '1',
  color: '',
  createdBy: [],
  assignedTo: [],
  stagesFilter: [],
  tagsFilter: [],
  tagsLogic: '0',
  archived: false,
  // header: true,
  //headerType: 'stats',
  headerFilter: 'yes',
  chartFilter: 'no',
  notes: false,
  periodFilter: 'last_14_days',
  fromDate: '',
  toDate: '',
  //showHeader: true,
}

export interface ReportGeneralState {
  projectId: string
  isCreated: string | null
  color: string
  createdBy: string[]
  assignedTo: string[]
  stagesFilter: string[]
  tagsFilter: string[]
  tagsLogic: string,
  stages: IData[]
  tags: ITicketTag[]
  users: IUser[]
  archived: boolean
  // header: boolean
  headerType: string
  headerFilter: string //'yes' | 'no'
  chartFilter: string //'yes' | 'no'
  notes: boolean
  periodFilter: string
  periods: IReportPeriod[]
  fromDate: string
  toDate: string
  headerStatistics: IReportHeaderStatistics | null
  chartData: IData | null
  isIrrelevantHeaderStatistics: boolean
  isIrrelevantChartData: boolean
  //showHeader: boolean

  tickets: ITicket[]
  offset: number
  count: number
  onlyTable: boolean
}

const state: ReportGeneralState = {
  projectId: '',
  isCreated: filtersDefaultValues.isCreated,
  color: filtersDefaultValues.color,
  createdBy: filtersDefaultValues.createdBy,
  assignedTo: filtersDefaultValues.assignedTo,
  stagesFilter: filtersDefaultValues.stagesFilter,
  tagsFilter: filtersDefaultValues.tagsFilter,
  tagsLogic: filtersDefaultValues.tagsLogic,
  archived: filtersDefaultValues.archived,
  // header: filtersDefaultValues.header,
  headerFilter: filtersDefaultValues.headerFilter,
  chartFilter: filtersDefaultValues.chartFilter,
  notes: filtersDefaultValues.notes,
  periodFilter: filtersDefaultValues.periodFilter,
  fromDate: filtersDefaultValues.fromDate,
  toDate: filtersDefaultValues.toDate,
  //showHeader: filtersDefaultValues.showHeader,
  headerType: '',
  users: [],
  stages: [],
  tags: [],
  periods: [],
  headerStatistics: null,
  chartData: null,
  isIrrelevantHeaderStatistics: true,
  isIrrelevantChartData: true,

  tickets: [],
  offset: 0,
  count: 100,
  onlyTable: false,
}

const getters: GetterTree<ReportGeneralState, RootState> = {
  projectId: state => state.projectId,
  isCreated: state => state.isCreated,
  color: state => state.color,
  createdBy: state => state.createdBy,
  assignedTo: state => state.assignedTo,
  users: state => state.users,
  stages: state => state.stages,
  tags: state => state.tags,
  stagesFilter: state => state.stagesFilter,
  tagsFilter: state => state.tagsFilter,
  tagsLogic: state => state.tagsLogic,
  archived: state => state.archived,
  // header: state => state.header,
  headerType: state => state.headerType,
  headerFilter: state => state.headerFilter,
  chartFilter: state => state.chartFilter,
  notes: state => state.notes,
  periodFilter: state => state.periodFilter,
  periods: state => state.periods,
  fromDate: state => state.fromDate,
  toDate: state => state.toDate,
  headerStatistics: state => state.headerStatistics,
  chartData: state => state.chartData,
  isIrrelevantHeaderStatistics: state => state.isIrrelevantHeaderStatistics,
  isIrrelevantChartData: state => state.isIrrelevantChartData,

  //showHeader: state => state.showHeader,
  tickets: state => state.tickets,
  offset: state => state.offset,
  count: state => state.count,
  onlyTable: state => state.onlyTable,
}

const mutations: MutationTree<ReportGeneralState> = {
  setProjectId(state, id: string) {
    state.projectId = id
  },
  setColor(state, value: string) {
    state.color = value
  },
  setIsCreated(state, value) {
    state.isCreated = value === '1' ? '1' : null
  },
  setCreatedBy(state, value: string[]) {
    state.createdBy = Array.isArray(value) ? value : []
  },
  setAssignedTo(state, value: string[]) {
    state.assignedTo = Array.isArray(value) ? value : []
  },
  setStagesFilter(state, value: string[]) {
    state.stagesFilter = Array.isArray(value) ? value : []
  },
  setTagsFilter(state, value: string[]) {
    state.tagsFilter = Array.isArray(value) ? value : []
  },
  setTagsLogic(state, tagsLogic: '0' | '1') {
    state.tagsLogic = tagsLogic
  },
  setStages(state, value: IData[]) {
    state.stages = Array.isArray(value) ? value : []
  },
  setTags(state, value: ITicketTag[]) {
    state.tags = Array.isArray(value) ? value : []
  },
  setUsers(state, value: IUser[]) {
    state.users = [...value]
  },
  setArchived(state, value: boolean) {
    state.archived = value
  },
  // setHeader(state, value: boolean) {
  //   state.header = value
  // },
  setHeaderType(state, value: string) {
    state.headerType = value
  },
  setHeaderFilter(state, value: string) {
    state.headerFilter = value
  },
  setChartFilter(state, value: string) {
    state.chartFilter = value
  },
  setNotes(state, value: boolean) {
    state.notes = value
  },
  setPeriodFilter(state, value: string) {
    state.periodFilter = value
  },
  setPeriods(state, value: IReportPeriod[]) {
    state.periods = [...value]
  },
  setFromDate(state, value: string) {
    state.fromDate = value
  },
  setToDate(state, value: string) {
    state.toDate = value
  },
  setHeaderStatistics(state, value: IReportHeaderStatistics | null) {
    if (value) {
      state.headerStatistics = {...value}
    }
  },
  setChartData(state, value: IData | null) {
    if (value) {
      state.chartData = {...value}
    }
  },
  setIsIrrelevantHeaderStatistics(state, value: boolean) {
    state.isIrrelevantHeaderStatistics = value
  },
  setIsIrrelevantChartData(state, value: boolean) {
    state.isIrrelevantChartData = value
  },
  // setShowHeader(state, value: boolean) {
  //   state.showHeader = value
  // },

  setTickets(state, tickets: ITicket[]) {
    state.tickets = tickets || []
  },
  setFilterDefaultValue(state, filterName) {
    state[filterName] = filtersDefaultValues[filterName]
    console.log('store report_general', filterName, filtersDefaultValues[filterName], state[filterName], state)
  },
  setOffset(state, value: number) {
    state.offset = value
  },
  setCount(state, value: number) {
    state.count = value
  },
  setNoteText(state, {id, text, ticketId}) {
    console.log('ReportGeneral setNoteText success', id, text, ticketId)
    const ticket = state.tickets.find(item => item.id == ticketId)
    if (ticket) {
      const note = ticket.notes.find(item => item.id == id)
      if (note) {
        note.text = text
        delete note.short_text
      }
    }
  },
  setOnlyTable(state, value: boolean) {
    state.onlyTable = value
  },
}

const actions: ActionTree<ReportGeneralState, RootState> = {
  resetFilters({ commit }) {
    Object.keys(filtersDefaultValues).forEach(filterName => {
      commit('setFilterDefaultValue', filterName)
    })
  },
  resetState({ commit }) {
    // Object.keys(filtersDefaultValues).forEach(filterName => {
    //   commit('setFilterDefaultValue', filterName)
    // })

    commit('set_order', null)
    commit('set_reverse', null)
    commit('set_pageCurrent', 1)
    commit('set_pageLast', 1)
    commit('set_itemsCount', 0)

    commit('setTickets', [])
    commit('setStages', [])

    commit('setHeaderStatistics', {})
    commit('setOffset', 0)
    commit('setCount', 100)
    commit('setOnlyTable', false)

  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules : {
    datatable,
    panels,
  }

}
